import { StringUtil } from './stringUtil';
import config from '@/config/env-constants';
import _ from 'lodash';


export const loadText = (context, text, x, y, maxWidth, lineHeight, color) => {
  let words = text.split(' ');
  let line = '';
  let linesAdded = 0;
  let hasEllipsis = false;

  for (let n = 0; n < words.length; n++) {
    let testLine = line + words[n] + ' ';
    let metrics = context.measureText(testLine);
    let testWidth = metrics.width;

    context.fillStyle = color;
    if (testWidth > maxWidth && n > 0) {
      linesAdded++;

      if (linesAdded >= 3) {
        hasEllipsis = true;
        linesAdded -= 1;
      } else {
        context.fillText(line, x, y);
        y += lineHeight;
        line = words[n] + ' ';
      }
    } else {
      line = testLine;
    }
  }

  if (hasEllipsis) {
    line += '...';
    hasEllipsis = false;
  }

  // Truncate string if more than 30 characters
  line = StringUtil.truncate(line, config.maxQRCodeStrLength);

  context.fillText(line, x, y);

  return linesAdded;
};

export const loadTextWithStyle = (context, text, x, y, maxWidth, lineHeight, color, font) => {
  let line = '';
  let hasEllipsis = false;

  // Apply Text Style
  if (font) {
    context.font = font;
  }
  if (color) {
    context.fillStyle = color;
  }

  let textMetrics = context.measureText(text);
  let textWidth = textMetrics.width;
  if (textWidth > maxWidth) {
    line = StringUtil.truncate(text, config.maxQRCodeStrLength);
    hasEllipsis = true;
  } else {
    line = text;
  }

  if (hasEllipsis) {
    line += '...';
    hasEllipsis = false;
  }

  // Truncate string if more than 30 characters
  line = StringUtil.truncate(line, config.maxQRCodeStrLength);

  let lineMetrics = context.measureText(line);
  let lineWidth = lineMetrics.width;
  x = (maxWidth / 2) - (lineWidth / 2);
  context.fillText(line, x, y);

  return {
    x, y
  };
};

export const loadImages = (sources, callback) => {
  let images = {};
  let loadedImages = 0;
  let numImages = _.size(sources);

  for (let src in sources) {
    images[src] = new Image();
    images[src].crossOrigin = 'anonymous';
    images[src].onload = function () {
      if (++loadedImages >= numImages) {
        callback(images);
      }
    };
    images[src].src = sources[src];
  }
}